import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "./../bnilogo.jpg"; // Import your logo image

const Signup = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [memberName, setMemberName] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [category, setCategory] = useState("");
  const [bniConnectScreenshot, setBniConnectScreenshot] = useState(null);

  const handleSignUp = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      const formData = new FormData();
      formData.append("mobile", username);
      formData.append("name", memberName);
      formData.append("chapter", chapterName);
      formData.append("category", category);
      formData.append("company", companyName);
      formData.append("bniConnectScreenshot", bniConnectScreenshot);

      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/signup`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const responseData = response.data;

      if (responseData?.status == 'success') {
        toast.success("Your account is created. You will be notified once it is activate.");
        navigate("/");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <div className="container login-container d-flex flex-column align-items-center justify-content-center min-vh-100">
      {/* Logo Header */}
      <header className="login-header text-center mb-4">
        <img src={logo} alt="App Logo" className="login-logo mb-3" />
        <h1 className="login-title h4">Welcome to BNI Haryana</h1>
      </header>

      {/* Loading Spinner */}
      {loading ? (
        <div className="spinner-container d-flex flex-column align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="card p-4 shadow-sm w-50">
          <div className="card-body">
            <form onSubmit={handleSignUp}>
              <div className="form-group mb-3">
                <input
                  type="text"
                  id="memberName"
                  className="form-control"
                  placeholder="Member Name"
                  value={memberName}
                  onChange={(e) => setMemberName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="number"
                  id="username"
                  className="form-control"
                  placeholder="Enter your mobile number"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  id="chapterName"
                  className="form-control"
                  placeholder="Chapter Name"
                  value={chapterName}
                  onChange={(e) => setChapterName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  id="companyName"
                  className="form-control"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  id="category"
                  className="form-control"
                  placeholder="Category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="file"
                  id="bniConnectScreenshot"
                  className="form-control"
                  onChange={(e) => setBniConnectScreenshot(e.target.files[0])}
                  required
                />
              </div>
              {/* Button container for center alignment */}
              <div className="text-center">
                <button className="btn btn-red btn-sm" type="submit">
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
