import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "./../bnilogo.jpg"; // Import your logo image

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      const data = {
        mobile: username,
      };

      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sendOTP`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = response.data;

      if (responseData?.message === "OTP sent successfully") {
        // OTP sent successfully
        setOtpSent(true);
        setLoading(false);
      } else if (responseData?.token) {
        localStorage.setItem("signIn", responseData.token);
        localStorage.setItem("userId", responseData.user)
        toast.success("Login successfully");
        navigate("/");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      const data = {
        mobile: username,
        otp: otp,
      };

      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = response.data;

      if (responseData?.token) {
        localStorage.setItem("signIn", responseData.token);
        localStorage.setItem("userId", responseData.user)
        toast.success("Login successfully");
        navigate("/");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleSignUp = () => {
    navigate("/signup"); // Navigate to the signup page or component
  };

  return (
    <div className="container login-container d-flex flex-column align-items-center justify-content-center min-vh-100">
      {/* Logo Header */}
      <header className="login-header text-center mb-4">
        <img src={logo} alt="App Logo" className="login-logo mb-3" />
        <h1 className="login-title h4">Welcome to BNI Haryana</h1>
      </header>

      {/* Loading Spinner */}
      {loading ? (
        <div className="spinner-container d-flex flex-column align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="card p-4 shadow-sm w-50"> {/* Increased card width */}
          {!otpSent ? (
            <div className="card-body">
              <form onSubmit={handleLogin}>
                <div className="form-group mb-3">
                  <input
                    type="number"
                    id="username"
                    className="form-control"
                    placeholder="Enter your mobile number"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>

                {/* Button container for center alignment */}
                <div className="text-center">
                  <button className="btn btn-red btn-sm" type="submit">
                    Login
                  </button>                  
                </div>
                <div className="text-center mt-3">
                  <button className="btn btn-link" onClick={handleSignUp}>
                      Not a Member? Signup now
                  </button>
              </div>
              </form>
            </div>
          ) : (
            <div className="card-body">
              <form onSubmit={handleVerifyOtp}>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    id="otp"
                    className="form-control"
                    placeholder="Enter the OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>

                {/* Button container for center alignment */}
                <div className="text-center">
                  <button className="btn btn-red btn-sm" type="submit">
                    Verify OTP
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Login;