import "./App.css";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/login";
import Signup from './pages/signup'; // Import the Signup component
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Organization from "./pages/organization";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("signIn");

  const checkTokenExpire = (exp) => {
    return new Date() > new Date(exp * 1000);
  };
  const checkAuth = () => {
    const user = localStorage.getItem("signIn");
    if (user) {
      const { exp } = jwtDecode(localStorage.getItem("signIn"));
      if (checkTokenExpire(exp)) {
        return true;
      }
    }
    return false;
  };
  const isExpire = checkAuth();
  if (isExpire) {
    localStorage.removeItem("signIn");
    localStorage.removeItem("userId");
    <Navigate to="/login" />;
  }
  const isLoginPage = location.pathname.includes("/login");
  const isSignupPage = location.pathname.includes("/signup");

  useEffect(() => {
    if(isSignupPage === true) {
      navigate("/signup");
    } else if (isExpire || (isLoginPage === false && token === null)) {
      navigate("/login");
    }
  }, [isExpire, isSignupPage, isLoginPage, navigate, token]);
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Organization />} />
        <Route path="/signup" element={<Signup />} /> {/* Add this line */}
        <Route path="/login" element={<Login />} />
        {/* <Route path='/company-form' element={<CompanyForm />} /> */}
      </Routes>
    </>
  );
}

export default App;
