/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import ViewCompany from "./viewCompany";

const CompanyForm = () => {
  const [formData, setFormData] = useState({
    contactPerson: "",
    companyName: "",
    mobile: "",
    city: "",
    email: "",
    position: "",
    category: null,
    subcategory: null,
  });
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const userId = localStorage.getItem("userId");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const categoriesData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories`
      );
      setCategories(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const subCategoriesData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/subcategories/${formData.category}`
      );
      setSubcategories(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-organization`,
        {
          userId,
          ...formData,
        }
      );
      if (response.status === 201) {
        setLoading(false);
        toast.success("Information addeed successfully");
        setFormData({
          contactPerson: "",
          companyName: "",
          mobile: "",
          email: "",
          position: "",
          city: "",
          category: "",
          subcategory: "",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    categoriesData();
  }, []);

  useEffect(() => {
    if (formData.category) {
      subCategoriesData();
    }
  }, [formData.category]);

  return (
    <>
      {loading ? (
        <div className="spinner-container">
          <Spinner animation="grow" variant="secondary" />
        </div>
      ) : (
        <div className="container">
          <div className="row justify-content-center mt-3">
            <div className="col-md-4">
              <div className="card shadow">
                <div className="card-body">
                  <h3
                    className="mb-4"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Company Details
                  </h3>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group row mb-2">
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Contact Person"
                          name="contactPerson"
                          value={formData.contactPerson}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control w-100"
                          placeholder="Company Name"
                          name="companyName"
                          value={formData.companyName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <div className="col-sm-12">
                        <input
                          type="number"
                          className="form-control w-100"
                          placeholder="Mobile"
                          name="mobile"
                          maxLength="10"
                          value={formData.mobile}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control w-100"
                          placeholder="City"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <div className="col-sm-12">
                        {/* <select
                          name="category"
                          className={`form-control w-100`}
                          value={formData.category}
                          onChange={handleChange}
                        >
                          <option value="">Select Category</option>
                          {categories?.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.category}
                            </option>
                          ))}
                        </select> */}
                          <input
                          type="text"
                          className="form-control w-100"
                          placeholder="Category"
                          name="category"
                          value={formData.category}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <div className="col-sm-12">
                        {/* <select
                          name="subcategory"
                          className={`form-control w-100`}
                          value={formData.subcategory}
                          onChange={handleChange}
                        >
                          <option value="">Select Subcategory</option>
                          {subcategories?.map((value, index) => (
                            <option key={index} value={value.id}>
                              {value.subcategory}
                            </option>
                          ))}
                        </select> */}
                          <input
                          type="text"
                          className="form-control w-100"
                          placeholder="Sub Category"
                          name="subcategory"
                          value={formData.subcategory}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <div className="col-sm-12">
                        <input
                          type="email"
                          className="form-control w-100"
                          placeholder="Email (Optional)"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control w-100"
                          placeholder="Position (Optional)"
                          name="position"
                          value={formData.position}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="login-btn">
                      <button
                        type="submit"
                        className="btn"
                        style={{ backgroundColor: '#d52031', color: 'white' }} // Inline style for button color
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <ViewCompany />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyForm;
