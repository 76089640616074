import axios from "axios";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { TextField, Button, Box, Typography, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const CompanyFilter = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [noRecords, setNoRecords] = useState("");

  const handleSearch = async () => {
    if (search.trim() === "") {
      setData([]);
      setNoRecords("Please enter a search term");
    } else {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/search-organization`,
          {
            params: {
              contactPerson: search,
              companyName: search,
            },
          }
        );
        const searchResults = response.data.result;
        if (searchResults.length === 0) {
          setNoRecords("No records found");
        } else {
          setNoRecords("");
        }
        const detailedResults = await Promise.all(
          searchResults.map(async (result) => {
            const detailResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/member/${result.userId}`
            );
            return { ...result, ...detailResponse.data.result };
          })
        );

        setData(detailedResults);
      } catch (error) {
        setData([]);
        setNoRecords(error.response?.data?.message || "An error occurred");
      }
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          <TextField
            id="search"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Search for companies or contacts..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          />
          <Button
            variant="contained"
            sx={{ backgroundColor: '#d52031', '&:hover': { backgroundColor: '#b71c1c' } }}
            onClick={handleSearch}
          >
            <SearchIcon />
          </Button>
        </Box>
      </Paper>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {noRecords && (
          <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 2 }}>
            {noRecords}
          </Typography>
        )}
        {data.map((record) => (
          <Card key={record.id} className="mb-2" style={{ width: '80%' }}>
            <Card.Body>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6">Company Information</Typography>
                  <Typography><strong>Company Name:</strong> {record.companyName}</Typography>
                  <Typography><strong>Category:</strong> {record.category}</Typography>
                  <Typography><strong>Contact Person:</strong> {record.contactPerson}</Typography>
                </Box>
                <Box>
                  <Typography variant="h6">Given by</Typography>
                  <Typography><strong>Name:</strong> {record[0].name}</Typography>
                  <Typography><strong>Chapter:</strong> {record[0].chapter}</Typography>
                  <Typography><strong>Mobile:</strong> {record[0].mobile}</Typography>
                </Box>
              </Box>
            </Card.Body>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default CompanyFilter;
