import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const ViewMembers = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const customBodyRender = (value) => {
    if (value) {
      const imageUrl = `https://api.bniharyana.com${value}`;
      return (
        <img
          src={imageUrl}
          alt="BNI Screenshot"
          style={{ width: '60px', height: '60px', cursor: 'pointer' }} // Add a pointer cursor to indicate interactivity
          onClick={() => handleOpen(imageUrl)} // Pass the image URL to handleOpen
        />
      );
    } else {
      return '-';
    }
  };

  const handleOpen = (url) => {
    setImageUrl(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImageUrl("");
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/members/`);
      setData(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  const toggleMemberStatus = async (id, currentStatus) => {
    try {
      // Toggle status: if currentStatus is 1 (true/active), set to 0 (false/inactive) and vice versa
      const newStatus = currentStatus === 1 ? 0 : 1;
      await axios.put(`${process.env.REACT_APP_API_URL}/members/${id}/status`, { status: newStatus });
      toast.success(`Member status updated to ${newStatus === 1 ? "Active" : "Inactive"}`);
      // Refresh data after updating the status
      fetchData();
    } catch (error) {
      console.error("Error updating member status:", error);
      toast.error("Error updating member status");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "company",
      label: "Company",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "Mobile",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "chapter",
      label: "Chapter",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bniscreenshot",
      label: "BNI Screenshot",
      options: {
        filter: false,
        sort: false,
        customBodyRender: customBodyRender,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          value === 1 ? "Active" : "Inactive"
        ),
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          console.log(tableMeta);
          const memberId = tableMeta.rowData[8];
          const memberStatus = tableMeta.rowData[6]; // boolean status (0/1)
          return (
            <Button
              variant="contained"
              color={memberStatus === 1 ? 'secondary' : 'primary'}
              onClick={() => toggleMemberStatus(memberId, memberStatus)}
            >
              {memberStatus === 1 ? 'Deactivate' : 'Activate'}
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    download: false,
    print: false,
    selectableRows: "none",
    rowsPerPageOptions: [10, 20, 50],
    rowsPerPage: 10,
  };

  const theme = createTheme({
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
            borderRadius: '8px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: '#d52031',
            color: '#fff',
            fontWeight: 'bold',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          hover: {
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        title={"Manage Members"}
        data={data}
        columns={columns}
        options={options}
        sx={{
          '& .MuiTableCell-head': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
          },
          '& .MuiTableRow-hover:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      />
      {/* Dialog to display the image in a popup */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>BNI Screenshot</DialogTitle>
        <DialogContent>
          <img src={imageUrl} alt="BNI Screenshot" style={{ width: "100%" }} />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
    
  );
};

export default ViewMembers;
