import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { Button, Modal } from "react-bootstrap";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const ViewCompany = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizationId, setOrganizationId] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const userId = localStorage.getItem("userId");

  const handleOpenModal = (id) => {
    setIsModalOpen(true);
    setOrganizationId(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteProject = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/delete-organization/${organizationId}`
      );
      if (response.status === 200) {
        setIsModalOpen(false);
        fetchData();
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error("This organization is not deleted");
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-organizations/${userId}`
      );
      setData(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const categoriesData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories`
      );
      setCategories(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const subCategoriesData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/subcategories`
      );
      setSubcategories(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCategoryName = (categoryId) => {
    const category = categories.find((cat) => cat.id === Number(categoryId));
    return category ? category.category : "N/A";
  };

  const getSubcategoryName = (subcategoryId) => {
    const subcategory = subcategories.find(
      (subcat) => subcat.id === Number(subcategoryId)
    );
    return subcategory ? subcategory.subcategory : "N/A";
  };

  useEffect(() => {
    categoriesData();
    subCategoriesData();
    fetchData();
  }, [userId]);

  const columns = [
    {
      name: "contactPerson",
      label: "Contact Person",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "companyName",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "Mobile No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "subcategory",
      label: "Sub Category",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "position",
      label: "Position",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Delete",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton
              onClick={() => handleOpenModal(data[dataIndex].id)}
              sx={{ color: '#d52031' }} // Apply color style
            >
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    download: false,
    print: false,
    selectableRows: "none",
    rowsPerPageOptions: [10, 20, 50],
    rowsPerPage: 10,
  };

  return (
    <>
      <MUIDataTable
        title={"Company List"}
        data={data.map((item) => {
          return [
            item.contactPerson,
            item.companyName,
            item.mobile,
            item.city,
            item.category,
            item.subcategory,            
            item.email,
            item.position,
            item.id, // This will be used in the customBodyRenderLite function
          ];
        })}
        columns={columns}
        options={options}
      />

      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this organization?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteProject}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewCompany;
