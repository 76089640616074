import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import ViewMembersList from "./viewMembers";

const MembersForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    mobile: "",
    chapter: "",
    category: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/createMembers`,
        formData
      );
      if (response.status === 201) {
        toast.success("Member created successfully");
        setFormData({
          name: "",
          company: "",
          mobile: "",
          chapter: "",
          category: "",
        });
      } else {
        toast.error("Failed to create member");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="spinner-container">
          <Spinner animation="grow" variant="secondary" />
        </div>
      ) : (
        <div className="container">
          <div className="row justify-content-center mt-3">
            {/* <div className="col-md-4">
              <div className="card shadow">
                <div className="card-body">
                  <h3 className="mb-4" style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Add Member
                  </h3>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Chapter"
                        name="chapter"
                        value={formData.chapter}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Category"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn"
                        style={{ backgroundColor: '#d52031', color: 'white' }} // Inline style for button color
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
            <div className="col-md-12">
              <ViewMembersList />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MembersForm;
