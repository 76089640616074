/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import CompanyForm from "./companyForm";
import CompanyFilter from "./companyFilter";
import MembersForm from "./memberForm";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import logo from "./../bnilogo.jpg"; // Import your logo image
import { FaUsers, FaGift, FaQuestion, FaSignOutAlt } from 'react-icons/fa';

const Organization = () => {
  const navigate = useNavigate();
  const [memberForm, setMemberForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState(false);
  const userId = parseInt(localStorage.getItem("userId"));

  const handleMembersClick = () => {
    setMemberForm(true);
    setShowForm(false);
    setFilter(false);
  };

  const handleGiveClick = () => {
    setShowForm(true);
    setFilter(false);
    setMemberForm(false);
  };

  const handleAskClick = () => {
    setFilter(true);
    setShowForm(false);
    setMemberForm(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("signIn");
    localStorage.removeItem("userId");
    navigate("/login");
  };

  const isAnyComponentActive = memberForm || showForm || filter;

  return (
    <>     
      <Navbar className="header-navbar" expand="lg">
            <Container>
                {/* Replace the text with an image */}
                <Navbar.Brand href="#">
                    <img src={logo} alt="App Logo" className="login-logo mb-2" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                    <Nav className="me-auto">
                        {
                          userId === 1 || userId === 2?
                        <Nav.Link onClick={handleMembersClick} className="nav-link">
                            <FaUsers className="nav-link-icon" />
                            Manage Member
                        </Nav.Link>:
                        ""
                        }
                        <Nav.Link onClick={handleGiveClick} className="nav-link">
                            <FaGift className="nav-link-icon" />
                            Give
                        </Nav.Link>
                        <Nav.Link onClick={handleAskClick} className="nav-link">
                            <FaQuestion className="nav-link-icon" />
                            Ask
                        </Nav.Link>
                    </Nav>
                    </Nav>
                    <Button
                        variant="outline-danger"
                        onClick={handleLogout}
                        className="logout-button"
                    >
                        <FaSignOutAlt className="nav-link-icon" /> Logout
                    </Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
      <div className="container">
        {/* Mobile-only buttons */}
        {!isAnyComponentActive && (
          <div className="mobile-only mobile-button-box">
            <button className="mobile-button" onClick={handleGiveClick}>
              <FaGift className="mobile-button-icon" />
              Give
            </button>
            <button className="mobile-button" onClick={handleAskClick}>
              <FaQuestion className="mobile-button-icon" />
              Ask
            </button>
          </div>
        )}

        {memberForm && <MembersForm />}
        {showForm && <CompanyForm />}
        {filter && <CompanyFilter />}
      </div>
    </>
  );
};

export default Organization;
